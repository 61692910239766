import React from 'react'
import './Hero.css'
import arrow_icon from '../ASSETS/arrow.png'
import hero_image from '../ASSETS/home1.png'

export const Hero = () => {
  return (
    <div className='hero'>
    
    <div className='hero-left'>
    
    <h2>NEW ARRIVALS ONLY</h2>
    <div>
    <div className='hero-hand-icon'>
    
    <p>Latest</p>
    
    </div>
    <p>in our stores</p>
    <p>for everyone</p>
    </div>
    
    <div className='hero-latest-btn'>
    <div>Latest Collection</div>
    <img src={arrow_icon} alt="" />
    
    </div>
    </div>
    
    

    <div className='hero-right'>
    <div className='imagecontainer'>
    <img src={hero_image} alt="" />
    </div>
    </div>
    
    </div>
  )
}
