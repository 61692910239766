import React from 'react'
import { CartItems } from '../COMPONENTS/CartItems/CartItems'

export const Cart = () => {
  return (
    <div>
    <CartItems/>
    </div>
  )
}
