import React from 'react'
import './Offers.css'
import exclusive_image from '../ASSETS/tag.gif'

export const Offers = () => {
  return (
    <div className='offers'>
    <div className='offers-left'>
    <h1 className='toptop'>Exclusive</h1>
    <h1 className='toptop'>Offers For You</h1>
    <p>THE BEST OR NOTHING</p>
    <button>Check Now</button>
    
    </div>

    <div className='offers-right'>
    <img src={exclusive_image} alt="" />
    </div>

    </div>
  )
}
