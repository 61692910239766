import React from 'react'
import './Footer.css'
import footer_logo from '../ASSETS/Shareed_logo.png'
import instagram_icon from '../ASSETS/instagram_icon.png'
import pintester_icon from '../ASSETS/pintester_icon.png'
import whatsapp_icon from '../ASSETS/whatsapp_icon.png'

export const Footer = () => {
  return (
    <div className='footer'>
    <div className='footer-logo'>
    <img src={footer_logo} alt="" />
    <p>Luku Luku</p>
    </div>
    <ul className='footer-links'>
    <li>Company</li>
    <li>Products</li>
    <li>Offices</li>
    <li>About</li>
    <li>Contacts</li>
    </ul>
    <div className='footer-social-icons'>
    <div className='footer-icons-container'>
    <img src={instagram_icon} alt="" />
    </div>

    <div className='footer-icons-container'>
    <img src={pintester_icon} alt="" />
    </div>

    <div className='footer-icons-container'>
    <img src={whatsapp_icon} alt="" />
    </div>
    </div>
    <div className='footer-copyright'>
        <hr/>
        <p>copyright @ 2023 All Rights Reserved.</p>
    </div>
    </div>
  )
}