import React from 'react'
import './DescriptionBox.css'


export const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
    <div className='descriptionbox-navigator'>
    <div className='descriptionbox-nav-box'>
      Description
    </div>
      <div className='descriptionbox-nav-box fade'>
        Review (122)
      </div>
    </div>
    <div className='descriptionbox-description'>
      <p> That’s where MongoDB comes in: JSON documents created in your React.js front end can be sent to the Express.js server, 
      where they can be processed and (assuming they’re valid) stored directly in MongoDB for later retrieval. Again, if you’re building in the cloud,
       you’ll want to look at Atlas. If you’re looking to set up your own MERN stack, read on! </p>
    </div>
    </div>
    
  )
}