import React, { useContext, useState } from 'react'
import {FaBars, FaTimes} from 'react-icons/fa'
import { useRef } from 'react'
import './Navbar.css'
import logo from '../ASSETS/Shareed_logo.png'
import cart_icon from '../ASSETS/cart_icon.png'
import { Link } from 'react-router-dom'
import { ShopContext } from '../../CONTEXT/ShopContext'



export const navbar = () => {

const [menu,setMenu] = useState("shop");

const {getTotalCartItems}= useContext(ShopContext)

const navRef = useRef(null);

const shownavmenu = () => {
  navRef.current.classList.toggle('responsive_nav');
}




  return (
    <div className='navbar'>

<button className='nav-btn' onClick={shownavmenu}>
        <FaBars/>
        </button>

    <div className='nav-logo'>
      <img src={logo} alt="" />
      <p>Luku Luku</p>
    </div>

    

    <ul className='nav-menu' ref={navRef}>
    <li onClick={()=>{setMenu("shop")}}> <Link style={{textDecoration: 'none'}} to='/'>Shop</Link>{menu==="shop"?<hr/>:null}</li>
    <li onClick={()=>{setMenu("men")}}><Link style={{textDecoration: 'none'}}  to='/men'>Men</Link>{menu==="men"?<hr/>:null}</li>
    <li onClick={()=>{setMenu("women")}}><Link style={{textDecoration: 'none'}}  to='/women'>Women</Link>{menu==="women"?<hr/>:null}</li>
    <li onClick={()=>{setMenu("kids")}}><Link style={{textDecoration: 'none'}}  to='/kids'>Kids</Link> {menu==="kids"?<hr/>:null}</li>
    <button className='nav-btn close' onClick={shownavmenu}>
        <FaTimes/>
        </button>
    </ul>
    
    


    <div className='nav-login-cart'>
    <Link to='/login'><button type="">Login</button> </Link>
    <Link className='cartt' to='/cart'><img src={cart_icon} alt="" /></Link>
    </div>
    <div className='nav-cart-count'>
      {getTotalCartItems()}
    </div>

        
    </div>

  )
}

export default navbar
