import React, { useContext } from 'react'
import './CSS/ShopCategory.css'
import all_product from '../COMPONENTS/ASSETS/all_product';
import { ShopContext } from '../CONTEXT/ShopContext';
import dropdown_icon from '../COMPONENTS/ASSETS/dropdown_icon.png'
import { Item } from '../COMPONENTS/Item/Item';

export const ShopCategory = (props) => {
  const {all_product} = useContext(ShopContext);
  return (
    <div className='shop-category'>
    <img className='shopcategory-banner' src={props.banner} alt="" />
    <div className='shopcategory-indexsort'>
    <p>
    <span>Showing 1-12 </span> out of 36
    
    </p>
      <div className='shopcategory-sort'>
      sort by <img src={dropdown_icon} alt="" />
        
      </div>
    </div>
    <div className='shopcategory-products'>
    {all_product.map((item, i)=>{
        if (props.category===item.category){
        return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/>
      }
      else {
        return null;
      }
    })}
      
    </div>
    </div>
  )
}

export default ShopCategory;
