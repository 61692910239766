import React from 'react'
import { Hero } from '../COMPONENTS/Hero/Hero';
import { Popular } from '../COMPONENTS/Popular/Popular';
import { Offers } from '../COMPONENTS/Offers/Offers';
import { NewCollections } from '../COMPONENTS/NewCollections/NewCollections';
import { NewsLetter } from '../COMPONENTS/NewsLetter/NewsLetter';


export const Shop = () => {
  return (
    <div>
    
    <Hero/>
    <Popular/>
    <Offers/>
    <NewCollections/>
    <NewsLetter/>
    </div>
  )
}
