import React, { useContext } from 'react'
import all_product from '../COMPONENTS/ASSETS/all_product';
import { ShopContext } from '../CONTEXT/ShopContext';
import { useParams } from 'react-router-dom';
import { Breadcrum } from '../COMPONENTS/Breadcrums/Breadcrum';
import { ProductDisplay } from '../COMPONENTS/ProductDisplay/ProductDisplay';
import { DescriptionBox } from '../COMPONENTS/DescriptionBox/DescriptionBox';
import { RelatedProducts } from '../COMPONENTS/RelatedProducts/RelatedProducts';


export const Product = () => {
  const {all_product}= useContext(ShopContext);
  const {productId}= useParams();
  const product = all_product.find((e)=> e.id === Number(productId))
  return (
    <div>
    <Breadcrum product={product} />
    <ProductDisplay product={product} />
    <DescriptionBox/>
    <RelatedProducts product={product} />
    
    </div>
  )
}
