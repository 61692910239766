import React from 'react'
import './App.css';
import Navbar from './COMPONENTS/Navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Shop } from './PAGES/Shop';
import { ShopCategory } from './PAGES/ShopCategory';
import { Product } from './PAGES/Product';
import { LoginSignup } from './PAGES/LoginSignup';
import { Cart } from './PAGES/Cart';
import { Footer } from './COMPONENTS/Footer/Footer';
import men_banner from './COMPONENTS/ASSETS/Menclothing.jpg'
import women_banner from './COMPONENTS/ASSETS/banner-women.jpg'
import kids_banner from './COMPONENTS/ASSETS/banner-Kids.jpg'


  function App() {
    return (
      <div>
      <BrowserRouter>
        <Navbar/>
        <Routes>
        <Route path='/' element={<Shop/>}/>
        <Route path='/men' element={<ShopCategory banner={men_banner} category="men"/>}/>
        <Route path='/women' element={<ShopCategory banner={women_banner} category="women"/>}/>
        <Route path='/kids' element={<ShopCategory banner={kids_banner} category="kid"/>}/>

        <Route path='/product' element={<Product/>}>
        <Route path=':productId' element={<Product/>}/>
        </Route>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/login' element={<LoginSignup/>}/>
        </Routes>
        <Footer/>

        </BrowserRouter>
      </div>
    );
  }


export default App;
